import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";
import Comp_InputFile from "components/InputFile";

import { GetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_Services } from "services/Register";
import { Svg_Delete, Svg_Opt1, Svg_Opt2, Svg_Opt3, Svg_Opt4, Svg_Opt5, Svg_Opt6, Svg_Opt7, Svg_Opt8 } from "services/SvgFile";

import { colorIcon, colorIcon3, optLayout, ShowPriceBr } from "fixedData";

export default function Page_Services_Details(props){

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('services'));

    const [ tag, setTag ] = useState(InitialData('tag'));
    const [ name, setName ] = useState(InitialData('name'));
    const [ price, setPrice ] = useState(InitialData('price'));
    const [ percentage, setPercentage ] = useState(InitialData('percentage'));
    const [ text, setText ] = useState(InitialData('text'));

    const [ listCategory, setListCategory ] = useState(InitialData('list_category'));
    const [ referralLink, setReferralLink ] = useState(InitialData('referral_link'));
    const [ presentationText, setPresentationText ] = useState(InitialData('presentation_text'));
    const [ presentationLayout, setPresentationLayout ] = useState(InitialData('presentation_layout'));
    const [ presentation, setPresentation ] = useState(InitialData('presentation'));

    function InitialData(type){
        const newData = GetDataPage('services').find(item => item.id == idPage);   
        if(newData){
            return newData[type];
        }
        if(type === 'list_category' || type === 'presentation' || type === 'referral_link'){
            return [];
        }
        return '';
    }
    
    /* list_category */
    function ShowCategory(){
        const newData = [];
        GetDataPage('services_category').map((elem, index)=>{
            if(newData.find(item => item.value == elem.id)){ }else {
                newData.push({ "value": elem.id, "label": elem.name })
            }
        });
        return newData;
    }
    function DeleteDataCategory(id, index, value){
        if(id === 0){
            const newData = [...listCategory];
            newData.splice(index, 1);
            setListCategory(newData);
        }else {
            props.AltAccess("services", "delete_category_service", "Deletar categoria", id, value, 0);
        }
    }
    function HandleDataCategory(type, index, value){
        const newData = [...listCategory];
        newData[index][type] = value;
        setListCategory(newData);
    }
    /* end */
    
    /* referral_link */
    function DeleteDataLink(id, index, value){
        if(id === 0){
            const newData = [...referralLink];
            newData.splice(index, 1);
            setReferralLink(newData);
        }else {
            props.AltAccess("services", "delete_link", "Deletar link de referência", id, value, 0);
        }
    }
    function HandleDataLink(type, index, value){
        const newData = [...referralLink];
        newData[index][type] = value;
        setReferralLink(newData);
    }
    /* end */

    /* presentation */
    function OptSelectedLayout(value){
        const newData = [];
        for (let index = 0; index < value; index++) {
            newData.push({ "id": 0, "type": { "value": "img", "label": "Imagem" }, "title": "", "statusFile": false, "file": "", "link": "" });
        }
        setPresentation(newData);
        setPresentationLayout(value);
    }
    function TypeLayout(){
        switch (presentationLayout) {
            case 1:
                return <Svg_Opt1 className="opt_selected" color={ colorIcon3 } />;
            case 2:
                return <Svg_Opt2 className="opt_selected" color={ colorIcon3 } />;
            case 3:
                return <Svg_Opt3 className="opt_selected" color={ colorIcon3 } />;
            case 4:
                return <Svg_Opt4 className="opt_selected" color={ colorIcon3 } />;
            case 5:
                return <Svg_Opt5 className="opt_selected" color={ colorIcon3 } />;
            case 6:
                return <Svg_Opt6 className="opt_selected" color={ colorIcon3 } />;
            case 7:
                return <Svg_Opt7 className="opt_selected" color={ colorIcon3 } />;
            case 8:
                return <Svg_Opt8 className="opt_selected" color={ colorIcon3 } />;
        }
    }
    function HandleDataPresentation(type, index, value){
        const newData = [...presentation];
        newData[index][type] = value;
        setPresentation(newData);
    }
    function typeData(index, value){
        return (
            <Comp_Select index={ index } name="Tipo" className="type_data" opt={ optLayout } setValue={ HandleDataPresentation } value={ value } input="type" />
        );
    }
    function typeImg(index, title, file, statusFile){
        return (
            <>
                <Comp_Input className="" type="text" index={ index } setValue={ HandleDataPresentation } value={ title } input="title" maxLength={ 140 } placeholder=" " required={ false } name="Nome da imagem" />
    
                <Comp_InputFile index={ index } setValue={ HandleDataPresentation } value={ file } setStatus={ HandleDataPresentation } status={ statusFile } OpenFile={ props.OpenFile } thumbnail={ file } input="file" title1="Adicionar foto" title2="Foto adicionada" />
            </>
        )
    }
    function typeVideo(index, title, link, typeVideo){
        return (
            <>
                <Comp_Input className="title_video" type="text" index={ index } setValue={ HandleDataPresentation } value={ title } input="title" maxLength={ 140 } placeholder=" " required={ false } name="Título" />
                
                <Comp_Input className="" type="text" index={ index } setValue={ HandleDataPresentation } value={ link } input="link" maxLength={ 140 } placeholder=" " required={ true } name="Link do vídeo*" iconVideo={ typeVideo } OpenFile={ props.OpenFile } />
            </>
        )
    }
    function ShowDataLayout(){        
        switch (presentationLayout) {
            case 1:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "column" } }>
                        <div className="list_input_data border_dashed" style={ { width: "calc(100% - 24px)" } }>
                            { typeData(0, presentation[0]['type']) }
                            {
                                presentation[0]['type']['value'] === 'img' ? typeImg(0, presentation[0]['title'], presentation[0]['file'], presentation[0]['statusFile']) : typeVideo(0, presentation[0]['title'], presentation[0]['link'], presentation[0]['type']['value'])
                            }
                        </div>
                    </div>
                );
                
            case 2:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "column" } }>
                        <div className="list_input_data border_dashed" style={ { width: "calc(100% - 24px)" } }>
                            { typeData(0, presentation[0]['type']) }
                            {
                                presentation[0]['type']['value'] === 'img' ? typeImg(0, presentation[0]['title'], presentation[0]['file'], presentation[0]['statusFile']) : typeVideo(0, presentation[0]['title'], presentation[0]['link'], presentation[0]['type']['value'])
                            }
                        </div>
                        <div className="list_input_data border_dashed" style={ { width: "calc(100% - 24px)" } }>
                            { typeData(1, presentation[1]['type']) }
                            {
                                presentation[1]['type']['value'] === 'img' ? typeImg(1, presentation[1]['title'], presentation[1]['file'], presentation[1]['statusFile']) : typeVideo(1, presentation[1]['title'], presentation[1]['link'], presentation[0]['type']['value'])
                            }
                        </div>
                    </div>
                );

            case 3:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "column" } }>
                        <div className="list_input_data border_dashed" style={ { width: "calc(100% - 24px)" } }>
                            { typeData(0, presentation[0]['type']) }
                            {
                                presentation[0]['type']['value'] === 'img' ? typeImg(0, presentation[0]['title'], presentation[0]['file'], presentation[0]['statusFile']) : typeVideo(0, presentation[0]['title'], presentation[0]['link'], presentation[0]['type']['value'])
                            }
                        </div>

                        <div className="list_input_data" style={ { width: "100%" } }>
                            <div className={ presentation[1]['type']['value'] != 'img' || presentation[2]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(1, presentation[1]['type']) }
                                {
                                    presentation[1]['type']['value'] === 'img' ? typeImg(1, presentation[1]['title'], presentation[1]['file'], presentation[1]['statusFile']) : typeVideo(1, presentation[1]['title'], presentation[1]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                            <div className={ presentation[1]['type']['value'] != 'img' || presentation[2]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(2, presentation[2]['type']) }
                                {
                                    presentation[2]['type']['value'] === 'img' ? typeImg(2, presentation[2]['title'], presentation[2]['file'], presentation[2]['statusFile']) : typeVideo(2, presentation[2]['title'], presentation[2]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                        </div>
                    </div>
                );

            case 4:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "column" } }>
                        <div className="list_input_data" style={ { width: "100%" } }>
                            <div className={ presentation[0]['type']['value'] != 'img' || presentation[1]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(0, presentation[0]['type']) }
                                {
                                    presentation[0]['type']['value'] === 'img' ? typeImg(0, presentation[0]['title'], presentation[0]['file'], presentation[0]['statusFile']) : typeVideo(0, presentation[0]['title'], presentation[0]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                            <div className={ presentation[0]['type']['value'] != 'img' || presentation[1]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(1, presentation[1]['type']) }
                                {
                                    presentation[1]['type']['value'] === 'img' ? typeImg(1, presentation[1]['title'], presentation[1]['file'], presentation[1]['statusFile']) : typeVideo(1, presentation[1]['title'], presentation[1]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                        </div>
                        <div className="list_input_data border_dashed" style={ { width: "calc(100% - 24px)" } }>
                            { typeData(2, presentation[2]['type']) }
                            {
                                presentation[2]['type']['value'] === 'img' ? typeImg(2, presentation[2]['title'], presentation[2]['file'], presentation[2]['statusFile']) : typeVideo(2, presentation[2]['title'], presentation[2]['link'], presentation[0]['type']['value'])
                            }
                        </div>
                    </div>
                );

            case 5:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "column" } }>
                        <div className="list_input_data" style={ { width: "100%" } }>
                            <div className={ presentation[0]['type']['value'] != 'img' || presentation[1]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(0, presentation[0]['type']) }
                                {
                                    presentation[0]['type']['value'] === 'img' ? typeImg(0, presentation[0]['title'], presentation[0]['file'], presentation[0]['statusFile']) : typeVideo(0, presentation[0]['title'], presentation[0]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                            <div className={ presentation[0]['type']['value'] != 'img' || presentation[1]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(1, presentation[1]['type']) }
                                {
                                    presentation[1]['type']['value'] === 'img' ? typeImg(1, presentation[1]['title'], presentation[1]['file'], presentation[1]['statusFile']) : typeVideo(1, presentation[1]['title'], presentation[1]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                        </div>
                        <div className="list_input_data" style={ { width: "100%" } }>
                            <div className={ presentation[3]['type']['value'] != 'img' || presentation[4]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(3, presentation[3]['type']) }
                                {
                                    presentation[3]['type']['value'] === 'img' ? typeImg(3, presentation[3]['title'], presentation[3]['file'], presentation[3]['statusFile']) : typeVideo(3, presentation[3]['title'], presentation[3]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                            <div className={ presentation[3]['type']['value'] != 'img' || presentation[4]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(4, presentation[4]['type']) }
                                {
                                    presentation[4]['type']['value'] === 'img' ? typeImg(4, presentation[4]['title'], presentation[4]['file'], presentation[4]['statusFile']) : typeVideo(4, presentation[4]['title'], presentation[4]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                        </div>
                    </div>
                );

            case 6:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "column" } }>
                        <div className="list_input_data" style={ { width: "100%" } }>
                            <div className={ presentation[0]['type']['value'] != 'img' || presentation[1]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(0, presentation[0]['type']) }
                                {
                                    presentation[0]['type']['value'] === 'img' ? typeImg(0, presentation[0]['title'], presentation[0]['file'], presentation[0]['statusFile']) : typeVideo(0, presentation[0]['title'], presentation[0]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                            <div className={ presentation[0]['type']['value'] != 'img' || presentation[1]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(1, presentation[1]['type']) }
                                {
                                    presentation[1]['type']['value'] === 'img' ? typeImg(1, presentation[1]['title'], presentation[1]['file'], presentation[1]['statusFile']) : typeVideo(1, presentation[1]['title'], presentation[1]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                        </div>
                    </div>
                );

            case 7:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "row" } }>
                        <div className="list_input_data" style={ { width: "100%" } }>
                            <div className="border_dashed_">
                                { typeData(0, presentation[0]['type']) }
                                {
                                    presentation[0]['type']['value'] === 'img' ? typeImg(0, presentation[0]['title'], presentation[0]['file'], presentation[0]['statusFile']) : typeVideo(0, presentation[0]['title'], presentation[0]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                            <div className="border_dashed_">
                                { typeData(1, presentation[1]['type']) }
                                {
                                    presentation[1]['type']['value'] === 'img' ? typeImg(1, presentation[1]['title'], presentation[1]['file'], presentation[1]['statusFile']) : typeVideo(1, presentation[1]['title'], presentation[1]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                        </div>
                        <div className={
                            presentation[0]['type']['value'] === 'img' && presentation[1]['type']['value'] === 'img' ? 
                            "list_input_data height_2" :
                                presentation[0]['type']['value'] != 'img' && presentation[1]['type']['value'] === 'img' || 
                                presentation[0]['type']['value'] === 'img' && presentation[1]['type']['value'] != 'img' ?
                                "list_input_data height_3" : 
                                    presentation[0]['type']['value'] != 'img' && presentation[1]['type']['value'] != 'img' ? 
                                    "list_input_data height_4" : "list_input_data height_5" 
                            } style={ { alignItems: "flex-start", width: "100%" } }>
                            <div className="border_dashed_" style={ { height: "calc(100% - 24px)" } }>
                                { typeData(2, presentation[2]['type']) }
                                {
                                    presentation[2]['type']['value'] === 'img' ? typeImg(2, presentation[2]['title'], presentation[2]['file'], presentation[2]['statusFile']) : typeVideo(2, presentation[2]['title'], presentation[2]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                        </div>
                    </div>
                );

            case 8:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "row" } }>
                        <div className={
                            presentation[1]['type']['value'] === 'img' && presentation[2]['type']['value'] === 'img' ? 
                            "list_input_data height_2" :
                                presentation[1]['type']['value'] != 'img' && presentation[2]['type']['value'] === 'img' || 
                                presentation[1]['type']['value'] === 'img' && presentation[2]['type']['value'] != 'img' ?
                                "list_input_data height_3" : 
                                    presentation[1]['type']['value'] != 'img' && presentation[2]['type']['value'] != 'img' ? 
                                    "list_input_data height_4" : "list_input_data height_5" 
                            
                        } style={ { alignItems: "flex-start", width: "100%" } }>
                            <div className="border_dashed_" style={ { height: "calc(100% - 24px)" } }>
                                { typeData(0, presentation[0]['type']) }
                                {
                                    presentation[0]['type']['value'] === 'img' ? typeImg(0, presentation[0]['title'], presentation[0]['file'], presentation[0]['statusFile']) : typeVideo(0, presentation[0]['title'], presentation[0]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                        </div>
                        <div className="list_input_data" style={ { width: "100%" } }>
                            <div className="border_dashed_">
                                { typeData(1, presentation[1]['type']) }
                                {
                                    presentation[1]['type']['value'] === 'img' ? typeImg(1, presentation[1]['title'], presentation[1]['file'], presentation[1]['statusFile']) : typeVideo(1, presentation[1]['title'], presentation[1]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                            <div className="border_dashed_">
                                { typeData(2, presentation[2]['type']) }
                                {
                                    presentation[2]['type']['value'] === 'img' ? typeImg(2, presentation[2]['title'], presentation[2]['file'], presentation[2]['statusFile']) : typeVideo(2, presentation[2]['title'], presentation[2]['link'], presentation[0]['type']['value'])
                                }
                            </div>
                        </div>
                    </div>
                );
        }
    }
    /* end */

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Services(props.userId, idPage, tag, name, price, percentage, text, listCategory, referralLink, presentationText, presentationLayout, presentation, props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterDataPage('services', setDataPage);
        return ()=>{
            UnRegisterDataPage('services', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));

        setTag(InitialData('tag'));
        setName(InitialData('name'));
        setPrice(InitialData('price'));
        setPercentage(InitialData('percentage'));
        setText(InitialData('text'));

        setListCategory(InitialData('list_category'));
        setReferralLink(InitialData('referral_link'));
        setPresentationText(InitialData('presentation_text'));
        setPresentationLayout(InitialData('presentation_layout'));
        setPresentation(InitialData('presentation'));
    }, [dataPage, idPage]);   

    return(
        <form className="page_content page_services" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="no_return" search="" title="Detalhes do serviço" OpenPage={ props.OpenPage } page="services" idPage={ 0 } qtdPage={ 0 } historic={ [] } />
            
            <div className="show_page_data">
                <div className="type_title">
                    <div className="">Dados do serviço</div>
                </div>
                <div className="list_input_data">
                    <Comp_Input className="tag" type="text" index="not" setValue={ setTag } value={ tag } input="" maxLength={ 140 } placeholder=" " required={ true } name="Tag*" />

                    <Comp_Input className="" type="text" index="not" setValue={ setName } value={ name } input="" maxLength={ 140 } placeholder="" required={ true } name="Nome do serviço*" />

                    <Comp_Input className="price" type="text" index="not" setValue={ setPrice } value={ ShowPriceBr(price) } input="" maxLength={ 140 } placeholder="" required={ true } name="Preço R$*" />

                    <Comp_Input className="price" type="text" index="not" setValue={ setPercentage } value={ percentage } input="" maxLength={ 140 } placeholder="x%" required={ false } name="Porcetagem" />
                </div>

                <div className="list_input_data">
                    <Comp_Textarea index="not" name="Descrição" setValue={ setText } value={ text } />                  
                </div>
            </div>
            
            <div className="show_page_data">
                <div className="type_title">
                    <div className="">Lista das categorias</div>
                    <div className="" onClick={ ()=>{ setListCategory([...listCategory, { "id": 0, "id_category": { "value": 0, "label": "Selecione uma opção" } }]) } }>
                        Adicionar novo categoria
                    </div>
                </div>
                <div className="list_input_data">
                    {
                        listCategory.length > 0 ?
                            listCategory.map((elem, index)=>{
                                return(
                                    <div className="div_show_btn" key={ index }>
                                        <Comp_Select index={ index } name="" className="" opt={ ShowCategory() } setValue={ HandleDataCategory } value={ elem.id_category } input="id_category" />

                                        <div className="div_delete" onClick={ ()=>{ DeleteDataCategory(elem.id, index, elem.name) } }>
                                            <Svg_Delete className="icons" color={ colorIcon }/>
                                        </div>
                                    </div>
                                )
                            })
                        : <div className="no_data">Nenhuma categoria encontrada...</div>
                    }
                </div>
            </div>
            
            <div className="show_page_data">
                <div className="type_title">
                    <div className="">Dados para apresentação</div>
                </div>
                <div className="list_input_data">
                    <Comp_Textarea index="not" name="Descrição" setValue={ setPresentationText } value={ presentationText } />
                </div>

                <div className="list_input_data">
                    {
                        presentationLayout != '' ?
                        <div className="div_project">
                            <div className="type_layout">
                                <div className="opt_layout">
                                    <div className="">O layout definido foi: </div>
                                    <div className="">{ TypeLayout() }</div>
                                </div>
                                <div className="btn" onClick={ ()=>{ setPresentationLayout(''); } }>
                                    Alterar layout
                                </div>
                            </div>
                            
                            <div className="show_layout">
                                {
                                    presentation.length > 0 ? ShowDataLayout() : null
                                }
                            </div>
                        </div>
                        :
                        <div className="div_project">
                            <div className="title">Escolha uma das opções abaixa para montar o layout da apresentação</div>
                            <div className="div_opt">
                                <div className="" onClick={ ()=>{ OptSelectedLayout(1); } }>
                                    <Svg_Opt1 className="opt_data" color={ colorIcon3 } />
                                </div>
                                <div className="" onClick={ ()=>{ OptSelectedLayout(2); } }>
                                    <Svg_Opt2 className="opt_data" color={ colorIcon3 } />
                                </div>
                                <div className="" onClick={ ()=>{ OptSelectedLayout(3); } }>
                                    <Svg_Opt3 className="opt_data" color={ colorIcon3 } />
                                </div>
                                <div className="" onClick={ ()=>{ OptSelectedLayout(4); } }>
                                    <Svg_Opt4 className="opt_data" color={ colorIcon3 } />
                                </div>

                                <div className="" onClick={ ()=>{ OptSelectedLayout(5); } }>
                                    <Svg_Opt5 className="opt_data" color={ colorIcon3 } />
                                </div>
                                <div className="" onClick={ ()=>{ OptSelectedLayout(6); } }>
                                    <Svg_Opt6 className="opt_data" color={ colorIcon3 } />
                                </div>
                                <div className="" onClick={ ()=>{ OptSelectedLayout(7); } }>
                                    <Svg_Opt7 className="opt_data" color={ colorIcon3 } />
                                </div>
                                <div className="" onClick={ ()=>{ OptSelectedLayout(8); } }>
                                    <Svg_Opt8 className="opt_data" color={ colorIcon3 } />
                                </div>                                
                            </div>
                        </div>
                    }
                </div>
            </div>
            
            <div className="show_page_data">
                <div className="type_title">
                    <div className="">Link de referência</div>
                    <div className="" onClick={ ()=>{ setReferralLink([...referralLink, { "id": 0, "link": "" }]) } }>
                        Adicionar novo link
                    </div>
                </div>
                <div className="list_input_data">
                    {
                        referralLink.length > 0 ?
                            referralLink.map((elem, index)=>{
                                return(
                                    <div className="div_show_btn" key={ index }>
                                        <Comp_Input className="" type="text" index={ index } setValue={ HandleDataLink } value={ elem.link } input="link" maxLength={ 140 } placeholder="" required={ true } name="Link*" />

                                        <div className="div_delete" onClick={ ()=>{ DeleteDataLink(elem.id, index, elem.name) } }>
                                            <Svg_Delete className="icons" color={ colorIcon }/>
                                        </div>
                                    </div>
                                )
                            })
                        : <div className="no_data">Nenhum link de referência encontrado...</div>
                    }
                </div>
            </div>
        </form>
    )
}