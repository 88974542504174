import React, { useState, useEffect } from "react";

import './ReturnResponse.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { colorIconSecondary } from "fixedData";

import { Svg_ChekedReturn, Svg_Erro } from "services/SvgFile";

export default function PopUP_ReturnResponse(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    function ClosePopUp(type){
        if(type == 0){
            SetModalState('ReturnResponse', false);
        }
        if(type == 1){
            modalData.setStatusAll(false);
            modalData.OpenPage(modalData.name_page); 
            SetModalState('ReturnResponse', false);
        }
    }

    function TypeData(){
        switch (modalData.page) {
            case 'erro': case 'altStatus':
                return(
                    <>
                        <Svg_Erro color={ colorIconSecondary } className="icons_return" />
                        <div className="title_return">
                            { modalData.text == "" || modalData.text == undefined ? "Erro ao registrar as informações, tente novamente!" : modalData.text }
                        </div>
                    </>
                )

            default:
                return(
                    <>
                        <Svg_ChekedReturn color="#43a047" className="icons_return" />
                        <div className="title_return">
                            Informações registradas com sucesso.
                        </div>
                    </>
                )
        }
    }  

    useEffect(()=>{
        RegisterModalData('ReturnResponse', setModaldata);
        RegisterModalObserver('ReturnResponse', setShowPopUp);
    }, []);  

    return(
        (showPopUp ?
            <div className="PopUp PopUpCenter">
                <div className="all ReturnResponse">
                    <div className="div_data">
                        <div className="content">
                            {
                                TypeData()
                            }
                            {
                                modalData.page == "altStatus" ? 
                                <>
                                    <div className="title_return">
                                        Ao clicar na opção "Sim" os dados alterados serão perdidos
                                    </div>
                                    <div className="content div_confirmation">
                                        <div onClick={ ()=>{ ClosePopUp(1); } }>
                                            <div className="opt_select yes_update">Sim</div>
                                        </div>
                                        <div onClick={ ()=>{ ClosePopUp(0); } }>
                                            <div className="opt_select not_update">Não</div>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="close_return" onClick={ ()=>{ ClosePopUp(0) } }>
                                    Fechar
                                </div>
                            }                                
                        </div>
                    </div>
                </div>
                {
                    modalData.page == "altStatus" ? null :
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(0); } } />
                }
            </div>
        : <React.Fragment></React.Fragment>)
    )
};
