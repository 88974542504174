import './Topic.css';

import { Svg_AddNewData, Svg_ArrowRight, Svg_Save } from "services/SvgFile";

import { colorIcon, defaultColor } from "fixedData";

import Comp_Search from "components/Search";

export default function Comp_Topic(props){

    function ShowDataSegund(){
        switch (props.qtdPage) {
            case 1:
                break;
                
            case 2:
                return(
                    <>
                        <div className="div_show_segund" onClick={ ()=>{ props.OpenPage('currentPage', props.list1Page); props.OpenPage('currentPageId', 'remuve'); } }>
                            <div className="">
                                <Svg_AddNewData className="icons" />
                            </div>
                            <div className="">{ props.list1Name }</div>                            
                        </div>
                        <div className="div_show_segund" onClick={ ()=>{ props.OpenPage('currentPage', props.list2Page); props.OpenPage('currentPageId', 'remuve'); } }>
                            <div className="">
                                <Svg_AddNewData className="icons" />
                            </div>
                            <div className="">{ props.list2Name }</div> 
                        </div>
                    </>
                )
                
            case 3:
                break;
                
            case 4:
                break;
        }        
    }

    return(
        <div className="Comp_Topic">
            <div className="new_block">
                {
                    props.SearchInput === "no_return" ?
                    <>
                        <div className="title_page div_cursor" onClick={ ()=>{ props.OpenPage('currentPage', props.page); props.OpenPage('currentPageId', 'remuve'); } }>
                            <Svg_ArrowRight className="icons arrow_left" color={ defaultColor } />
                            <span className="">Voltar</span>
                        </div>
                        <div className="title_page">
                            { props.title }
                        </div>
                    </>
                    : 
                    <>
                        <div className="title_page">
                            { props.title }
                        </div>
                        <Comp_Search SearchInput={ props.SearchInput } search={ props.search } />
                    </>
                }
                {
                    props.type ? 
                        props.icon === "add" ? 
                            <div className="list_opt_alt_page">
                                { props.qtdPage > 0 ? ShowDataSegund() : null }
                                {
                                    props.OpenPopUp ? 
                                    <div type="submit" className="div_add_new_data" onClick={ ()=>{ props.OpenPopUp(props.idPage, "", "") } }>
                                        <div className="new_data_add">
                                            <Svg_AddNewData className="icons" />
                                        </div>
                                        <div className="name_btn_add">{ props.titleBtn }</div>
                                    </div>
                                    :
                                    <div type="submit" className="div_add_new_data" onClick={ ()=>{ props.OpenPage('currentPage', props.page); props.OpenPage('currentPageId', props.idPage) } }>
                                        <div className="new_data_add">
                                            <Svg_AddNewData className="icons" />
                                        </div>
                                        <div className="name_btn_add">{ props.titleBtn }</div>
                                    </div>
                                }
                            </div>
                        :
                            <div className="list_opt_alt_page">
                                { props.qtdPage > 0 ? ShowDataSegund() : null }
                                <button type="submit" className="div_add_new_data">
                                    <div className="new_data_add">
                                        <Svg_Save className="icons icon_save" color={ colorIcon } />
                                    </div>
                                    <div className="name_btn_add">Salvar</div>
                                </button>
                            </div>
                    : null
                }
            </div>
        </div>
    )
}