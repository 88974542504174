import { useState, useEffect } from "react";

import './Details.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";
import Comp_InputFile from "components/InputFile";

import { GetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_AccessDash } from "services/Register";
import { Svg_ClickMove, Svg_Eye, Svg_EyeClose } from "services/SvgFile";

import { colorIcon, optPage, tableColumnsBudget, tableColumnsClient } from "fixedData";

export default function Page_AccessDash_Details(props){

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('access_dash'));
    
    const [ typeMove, setTypeMove ] = useState('');
    const [ draggedIndexClient, setDraggedIndexClient ] = useState(null);
    const [ draggedIndexBudget, setDraggedIndexBudget ] = useState(null);

    const [ name, setName ] = useState(InitialData('name'));
    const [ email, setEmail ] = useState(InitialData('email'));
    const [ file, setFile ] = useState(InitialData('file'));
    const [ fileStatus, setFileStatus ] = useState(false);
    const [ pass, setPass ] = useState('');
    const [ access, setAccess ] = useState(InitialData('access'));
    const [ note, setNote ] = useState(InitialData('note'));
    const [ page, setPage ] = useState(InitialData('page'));
    
    const [ columnsClient, setColumnsClient ] = useState(InitialData('table_columns_client'));
    const [ columnsBudget, setColumnsBudget ] = useState(InitialData('table_columns_budget'));

    function InitialData(type){
        const newData = GetDataPage('access_dash').find(item => item.id == idPage);   
        if(newData){
            if(type === 'page'){
                if(newData['page'].length == 0){
                    return optPage;
                }
                return newData['page'];

            }else if(type === 'table_columns_budget'){
                if(newData['table_columns_budget'].length == 0){
                    return tableColumnsBudget;
                }
                return newData['table_columns_budget'];

            }else if(type === 'table_columns_client'){
                if(newData['table_columns_client'].length == 0){
                    return tableColumnsClient;
                }
                return newData['table_columns_client'];

            }else {
                return newData[type];
            }
        }
        if(type === 'access'){
            return { "value": 0, "label": "Usuário comum" };
        }
        if(type === 'table_columns_budget'){
            return tableColumnsBudget;
        }
        if(type === 'table_columns_client'){
            return tableColumnsClient;
        }
        if(type === 'page'){
            return optPage;
        }
        return '';
    }
    
    /* page */
    function HandleDataPage(type, index, value){
        const newData = [...page];
        newData[index][type] = value;
        setPage(newData);
    }
    /* end */

    /* dragged */
    function HandleDragStart(origin, setValue, index){
        setTypeMove(origin);
        setValue(index);
    };
    function HandleDragOver(e){
        e.preventDefault();
    };
    function HandleDrop(origin, value, setValue, contents, setContents, index){        
        if(origin == typeMove){  
            const newList = [...contents];
            const draggedItem = newList[value];

            newList.splice(value, 1);
            newList.splice(index, 0, draggedItem);
            
            setContents(newList);
            setValue(null);
            SaveData(origin, newList);   
        }else {
            props.CallbackError('Tem certeza que você quer misturar cliente com orçamento?')
        }
    };
    /* end */

    /* table */
    function HandleDataTable(origin, contents, setContents, type, index, value){
        const newData = [...contents];
        newData[index][type] = value;        
        setContents(newData);
        SaveData(origin, contents);
    }
    function ShowColunmTable(title, list, setList, draggedIndex, setDraggedIndex){
        return(
            <div className="show_page_data">
                <div className="type_title">
                    <div className="">{ title }</div>
                </div>
                <div className="list_input_data">
                    {
                        list.map((elem, index)=>{                                                     
                            return(
                                <div className="div_btn" key={ index } draggable onDragStart={ (e)=>{ HandleDragStart(elem.origin, setDraggedIndex, index) } } onDragOver={ (e)=>{ HandleDragOver(e) } } onDrop={ (e)=>{ HandleDrop(elem.origin, draggedIndex, setDraggedIndex, list, setList, index) } }>
                                    <div className=""><b>{ index + 1 }</b></div>
                                    <div className="">{ elem.label }</div>
                                    <div className="" onClick={ ()=>{ HandleDataTable(elem.origin, list, setList, 'statusTd', index, !elem.statusTd); } } data-tooltip-id="show_alert" data-tooltip-content={ elem.statusTd ? "Clique aqui ocultar essa coluna" : "Clique aqui para mostrar essa coluna" } data-tooltip-place="top">
                                        {
                                            elem.statusTd ?
                                            <Svg_Eye className="icons" color={ colorIcon } />
                                            :
                                            <Svg_EyeClose className="icons" color={ colorIcon } />
                                        }
                                    </div>
                                    <div className="div_move" data-tooltip-id="show_alert" data-tooltip-content="Clique e segure para alterar a ordem da coluna" data-tooltip-place="top">
                                        <Svg_ClickMove className="icons" color={ colorIcon } />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
    function ShowColunm(typePage){
        let table = page.find(item => item.page == typePage);        
        if(table.type['value'] === 'show_page'){
            return(
                typePage === 'client' ? 
                ShowColunmTable('Clientes - Colunas da tabela', columnsClient, setColumnsClient, draggedIndexClient, setDraggedIndexClient) : 
                ShowColunmTable('Orçamentos - Colunas da tabela', columnsBudget, setColumnsBudget, draggedIndexBudget, setDraggedIndexBudget)
            )
        }
        return null;
    }
    /* end */

    function SaveData(event, dataTD){        
        if(event === 'client'){
            props.setLoading(true);
            Reg_AccessDash(props.userId, idPage, name, email, file, pass, access, note, page, dataTD, columnsBudget, props.CallbackSuccess, props.CallbackError);

        }else if(event === 'budget'){
            props.setLoading(true);
            Reg_AccessDash(props.userId, idPage, name, email, file, pass, access, note, page, columnsClient, dataTD, props.CallbackSuccess, props.CallbackError);

        }else {
            if(event === ''){ }else {
                event.preventDefault();
            }
            props.setLoading(true);
            Reg_AccessDash(props.userId, idPage, name, email, file, pass, access, note, page, columnsClient, columnsBudget, props.CallbackSuccess, props.CallbackError);
        }
    }

    useEffect(()=>{
        RegisterDataPage('access_dash', setDataPage);
        return ()=>{
            UnRegisterDataPage('access_dash', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        
        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setFile(InitialData('file'));
        setFileStatus(false);
        setPass('');
        setAccess(InitialData('access'));
        setNote(InitialData('note'));
        setPage(InitialData('page'));
    }, [dataPage, idPage]);

    return(
        <form className="page_content page_access_dash" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="no_return" search="" title="Detalhes do acesso" OpenPage={ props.OpenPage } page="access_dash" idPage={ 0 } qtdPage={ 0 } historic={ [] } />
            
            <div className="show_page_data">
                <div className="type_title">
                    <div className="">Dados do acesso</div>
                </div>
                <div className="list_input_data">
                    <Comp_Input className="" type="text" index="not" setValue={ setName } value={ name } input="" maxLength={ 140 } placeholder="" required={ true } name="Nome" />
                    
                    <Comp_Input className="" type="email" index="not" setValue={ setEmail } value={ email } input="" maxLength={ 40 } placeholder="" required={ true } name="E-mail" />
                    
                    <Comp_Input className="password" type="password" index="not" setValue={ setPass } value={ pass } input="" maxLength={ 10 } placeholder="*****" required={ idPage === 0 ? true : false } name="Senha" />
                    
                    {
                        idPage != props.userId ?
                            <Comp_Select index="not" name="Tipo de acesso" className="access" opt={ [{ "value": 0, "label": "Usuário comum" }, { "value": 1, "label": "Administrador" }] } setValue={ setAccess } value={ access } input="" />
                        : null
                    }  

                    <Comp_InputFile index="not" setValue={ setFile } value={ file } setStatus={ setFileStatus } status={ fileStatus } OpenFile={ props.OpenFile } thumbnail={ file } title1="Adicionar foto" title2="Foto adicionado" />

                    <Comp_Textarea index="not" name="Observação" setValue={ setNote } value={ note } />                  
                </div>
            </div>
            
            {
                access['value'] === 1 ? null :
                <div className="show_page_data">
                    <div className="type_title">
                        <div className="">Acesso as páginas</div>
                    </div>
                    <div className="list_input_data">
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <th width="20" align="center">#</th>
                                    <th>Página</th>
                                    <th width="110">Acesso</th>
                                </tr>
                            </tbody>
                            <tbody>
                                {
                                    page.map((elem, index)=>{
                                        return(
                                            <tr key={ index }>
                                                <td align="center">{ index + 1 }</td>
                                                <td>{ elem.name }</td>
                                                <td>                                                        
                                                    <Comp_Select index={ index } name="" className="" opt={ [{ "value": "hide_page", "label": "Sem acesso" }, { "value": "show_page", "label": "Acesso liberado" }] } setValue={ HandleDataPage } value={ elem.type } input="type" />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }

            {
                idPage === 0 ? null :
                access['value'] === 1 ?
                <>
                    {
                        ShowColunmTable('Clientes - Colunas da tabela', columnsClient, setColumnsClient, draggedIndexClient, setDraggedIndexClient) 
                    }
                    {
                        ShowColunmTable('Orçamentos - Colunas da tabela', columnsBudget, setColumnsBudget, draggedIndexBudget, setDraggedIndexBudget)
                    }
                </>
                : 
                <>
                    { ShowColunm('client') }
                    { ShowColunm('budgets') }
                </>
            }
            <Tooltip id="show_alert" />
        </form>
    )
}