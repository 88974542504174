import React, { useState, useEffect } from "react";

import './ShowFile.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import Iframe from 'react-iframe';

import { Svg_Close } from "services/SvgFile";

import { colorIcon } from "fixedData";

export default function PopUP_ShowFile(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    function OpenFile(){
        switch (modalData.type) {
            case 'img':
                return <img alt="img" src={ modalData.file } className="show_img" />;

            case 'pdf':
                return <Iframe url={ modalData.file } width="98%" height="400px" id="" className="" display="block" position="relative" />

            case 'vimeo':
                let showInfFileVimeo = modalData.file.split('/');
                let infFileVimeo = showInfFileVimeo[showInfFileVimeo.length - 1];
                return <Iframe className="div_video" src={ "https://player.vimeo.com/video/" + infFileVimeo } frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;

            case 'youtube':
                if(modalData.file.includes('embed')){
                    let showInfFileYoutube = modalData.file.split('/');
                    let infFileYoutube = showInfFileYoutube[showInfFileYoutube.length - 1];
                    return <Iframe className="div_video" src={ "https://www.youtube.com/embed/" + infFileYoutube } frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;

                }else {
                    
                    if(modalData.file.includes('youtu.be')){
                        let showInfFileYoutube = modalData.file.split('/');
                        let showInfFileYoutube_ = showInfFileYoutube[showInfFileYoutube.length - 1];
                        let linkYoutube = showInfFileYoutube_.split('?');

                        return <Iframe className="div_video" src={ "https://www.youtube.com/embed/" + linkYoutube[0] } frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;

                    }else {
                        let linkYoutube = modalData.file.split('=');
                        return <Iframe className="div_video" src={ "https://www.youtube.com/embed/" + linkYoutube[1] } frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;
                    }
                }

            case 'video_local':
                return (
                    <video width="98%" height="400px" controls>
                        <source src={ modalData.file } type="video/mp4" />
                    </video>
                );
        }
    }

    function ClosePopUp(){
        SetModalState('ShowFile', false);
    }

    useEffect(()=>{
        RegisterModalData('ShowFile', setModaldata);
        RegisterModalObserver('ShowFile', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp){
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div className="PopUp">
                <div className="all show_file">
                    <div className="div_data type_div">
                        <div className="title" style={ { borderColor: colorIcon } }></div>
                        <div className="close" style={ { borderColor: colorIcon } } onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color={ colorIcon } className="icons" />
                        </div>
                    </div>
                    <div className="div_data">
                        <div className="content">
                            { OpenFile() }
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
