import { useState, useEffect } from "react";

import './Category.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { colorIcon, defaultColor } from "fixedData";

import Comp_Topic from "components/Topic";
import Comp_AltOrderList from "components/AltOrderList";

import { SetModalData, SetModalState } from "interface/PopUp";
import { Svg_Delete, Svg_Edit, Svg_Vimeo, Svg_Youtube } from "services/SvgFile";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_ServicesCategoryColor } from "services/Register";

export default function Page_Services_Category(props){

    const [ search, setSearch ] = useState('');
    const [ typeOrder, setTypeOrder ] = useState('');
    const [ showPageData, setShowPageData ] = useState(GetDataPage('services_category'));

    const [ listDataOn, setListDataOn ] = useState(InitialData(1));
    const [ listDataOff, setListDataOff ] = useState(GetDataPage(0));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listDataOn.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listDataOn.slice(startItens, endItens);

    function InitialData(value){
        const newData = GetDataPage('services_category').filter(item => item.status == value);
        return newData;
    }

    function ShowDataCategory(id){
        SetModalData('Category', { "id": id });
        SetModalState('Category', true);
    }

    function HandleDataColor(id, color){        
        Reg_ServicesCategoryColor(props.userId, id, color, props.CallbackSuccess, props.CallbackError);
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('services_category').forEach(item =>{
                if(item.status === 1){
                    if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListDataOn(duplicate);
        }else {
            setListDataOn(GetDataPage('services_category'));
        }
        setSearch(value);
    }

    useEffect(()=>{
        RegisterDataPage('services_category', setShowPageData);
        return ()=>{
            UnRegisterDataPage('services_category', setShowPageData);
        };
    }, []);

    useEffect(()=>{
        setListDataOn(InitialData(1));
        setListDataOff(GetDataPage(0));
    }, [showPageData]);

    return(
        <div className="page_content page_services_category">
            <Comp_Topic type={ true } icon="add" SearchInput={ SearchInput } search={ search } title="Categorias" OpenPage={ props.OpenPage } page="" idPage={ 0 } titleBtn="Categoria" historic={ listDataOff } qtdPage={ 0 } OpenPopUp={ ShowDataCategory } />

            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center" style={ { backgroundColor: defaultColor } }>#</th>
                            <th width="60" align="center" style={ { backgroundColor: defaultColor } }>Arquivo</th>
                            <th style={ { backgroundColor: defaultColor } }>
                                <div className="div_opt_alt" style={ { justifyContent: "flex-start" } }>
                                    <div className="">Nome</div>
                                    <Comp_AltOrderList list={ currentItens } setList={ setListDataOn } typeOrder={ typeOrder } setTypeOrder={ setTypeOrder } type="name" />
                                </div>
                            </th>
                            <th width="20" align="left" style={ { backgroundColor: defaultColor } }>Cor</th>
                            <th width="60" align="right" style={ { backgroundColor: defaultColor } }>#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{                                
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td align="center">
                                            {
                                                elem.type['value'] === 'img' ?
                                                    elem.file ?
                                                        <img alt="img" src={ elem.thumbnail } className="show_img_td" onClick={ ()=>{ props.OpenFile(elem.type['value'], elem.file); } }data-tooltip-id="show_alert" data-tooltip-content="Clique aqui para ampliar a imagem" data-tooltip-place="top" />
                                                    : "---"
                                                :
                                                    <div className="" onClick={ ()=>{ props.OpenFile(elem.type['value'], elem.link); } } data-tooltip-id="show_alert" data-tooltip-content="Clique aqui para visualizar o vídeo" data-tooltip-place="top">
                                                        {
                                                            elem.type['value'] === 'vimeo' ?
                                                            <Svg_Vimeo className="icons" color={ colorIcon } />
                                                            :
                                                            <Svg_Youtube className="icons" color={ colorIcon } />
                                                        }
                                                    </div>
                                            }
                                        </td>
                                        <td>{ elem.name }</td>
                                        <td>
                                            <div className="color_td" style={ { background: elem.color } } onClick={ ()=>{ props.SetColor(elem.color, HandleDataColor, elem.id); } } data-tooltip-id="show_alert" data-tooltip-content="Clique aqui para alterar a cor" data-tooltip-place="top" />
                                        </td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                <div data-tooltip-id="show_alert" data-tooltip-content="Editar categoria" data-tooltip-place="top" onClick={ ()=>{ ShowDataCategory(elem.id) } } title="Editar categoria">
                                                    <Svg_Edit className="icons" color={ colorIcon }/>
                                                </div>
                                                
                                                <div data-tooltip-id="show_alert" data-tooltip-content="Deletar categoria" data-tooltip-place="top" onClick={ ()=>{ props.AltAccess("services", "delete_category", "Deletar categoria", elem.id, elem.name, 0); } } title="Deletar categoria">
                                                    <Svg_Delete className="icons" color={ colorIcon }/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td className="no_data" colSpan={ 4 }>Nenhum dado encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <Tooltip id="show_alert" />

                {
                    listDataOn.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}