export function cpfMask(cpf){
    return cpf.replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') 
}

export function cnpjMask(cnpj) {
    return cnpj.replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
}

export function cepMask(cep){
    return cep.replace(/\D/g, '')
    .replace(/(\d{5})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1') 
}

export function crmMask(crm){
    return crm.replace(/\D/g, '')
            .replace(/(\d{8})\d+?$/, '$1')
}

export function crfMask(crf){
    return crf.replace(/\D/g, '')
            .replace(/(\d{5})\d+?$/, '$1')
}

export function phoneMask(phone){
    return phone.replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, `$1-$2`)
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1')
}

export function instagramMask(instagram){
    return instagram
    .replace(/(^[A-Za-z0-9_]{1,15}$)/,'@$1')
}