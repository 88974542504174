import './Search.css';

import { colorIcon3 } from 'fixedData';

import { Svg_Search } from 'services/SvgFile';

export default function Comp_Search(props){
    return(
        <div className="input_search">                             
            <Svg_Search className="icons_search" color={ colorIcon3 } />
            <input type="text" className="search" onChange={ (e)=>{ props.SearchInput(e.target.value) } } value={ props.search } placeholder="digite aqui sua pesquisa..." style={ {  } } />
        </div>
    )
}