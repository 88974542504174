import { useState, useEffect } from "react";

import './Combo.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { colorIcon, defaultColor } from "fixedData";

import Comp_Topic from "components/Topic";
import Comp_AltOrderList from "components/AltOrderList";

import { SetModalData, SetModalState } from "interface/PopUp";
import { Svg_Delete, Svg_Edit, Svg_Vimeo, Svg_Youtube } from "services/SvgFile";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

export default function Page_Services_Combo(props){

    const [ search, setSearch ] = useState('');
    const [ typeOrder, setTypeOrder ] = useState('');
    const [ showPageData, setShowPageData ] = useState(GetDataPage('services_combo'));

    const [ listDataOn, setListDataOn ] = useState(InitialData(1));
    const [ listDataOff, setListDataOff ] = useState(GetDataPage(0));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listDataOn.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listDataOn.slice(startItens, endItens);

    function InitialData(value){
        const newData = GetDataPage('services_combo').filter(item => item.status == value);
        return newData;
    }

    function ShowDataCombo(id){
        // SetModalData('Combo', { "id": id });
        // SetModalState('Combo', true);
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('services_combo').forEach(item =>{
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListDataOn(duplicate);
        }else {
            setListDataOn(GetDataPage('services_combo'));
        }
        setSearch(value);
    }

    useEffect(()=>{
        RegisterDataPage('services_combo', setShowPageData);
        return ()=>{
            UnRegisterDataPage('services_combo', setShowPageData);
        };
    }, []);

    useEffect(()=>{
        setListDataOn(InitialData(1));
        setListDataOff(GetDataPage(0));
    }, [showPageData]);

    return(
        <div className="page_content page_services_combo">
            <Comp_Topic type={ true } icon="add" SearchInput={ SearchInput } search={ search } title="Combos" OpenPage={ props.OpenPage } page="" idPage={ 0 } titleBtn="Combo" historic={ listDataOff } qtdPage={ 0 } OpenPopUp={ ShowDataCombo } />

            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center" style={ { backgroundColor: defaultColor } }>#</th>
                            <th width="200" style={ { backgroundColor: defaultColor } }>
                                <div className="div_opt_alt" style={ { justifyContent: "flex-start" } }>
                                    <div className="">Nome</div>
                                    <Comp_AltOrderList list={ currentItens } setList={ setListDataOn } typeOrder={ typeOrder } setTypeOrder={ setTypeOrder } type="name" />
                                </div>
                            </th>
                            <th align="left" style={ { backgroundColor: defaultColor } }>Serviços</th>
                            <th width="60" align="right" style={ { backgroundColor: defaultColor } }>#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{                                
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.title }</td>
                                        <td>
                                        </td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                <div data-tooltip-id="show_alert" data-tooltip-content="Editar Combo" data-tooltip-place="top" onClick={ ()=>{ ShowDataCombo(elem.id) } } title="Editar combo">
                                                    <Svg_Edit className="icons" color={ colorIcon }/>
                                                </div>
                                                
                                                <div data-tooltip-id="show_alert" data-tooltip-content="Deletar combo" data-tooltip-place="top" onClick={ ()=>{ props.AltAccess("services", "delete_combo", "Deletar combo", elem.id, elem.name, 0); } } title="Deletar combo">
                                                    <Svg_Delete className="icons" color={ colorIcon }/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td className="no_data" colSpan={ 4 }>Nenhum dado encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <Tooltip id="show_alert" />

                {
                    listDataOn.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}