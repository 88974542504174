import { useState, useEffect } from "react";

import './Settings.css';

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Svg_Delete } from "services/SvgFile";
import { Reg_Settings } from "services/Register";

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";

import { colorIcon, FormatPrice, selectTypeEmail, ShowPriceBr } from "fixedData";

export default function Page_Settings(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('settings'));
    const [ listEmail, setListEmail ] = useState(InitialData('list_email'));
    const [ listAnnualGoal, setListAnnualGoal ] = useState(InitialData('annual_goal'));
    
    function InitialData(type){
        return GetDataPage('settings')[type];
    }

    /* list annual goal */
    function DeleteAnnualGoal(id, index, value){
        if(id == 0){            
            const newData = [...listAnnualGoal];
            newData.splice(index, 1);
            setListAnnualGoal(newData);
        }else {
            props.AltAccess("settings", "delete_annual_goal", "Deletar meta", id, value, 0);
        }
    }
    function HandleDataAnnualGoal(type, index, value){
        let priceAll = 0.00;
        const newData = [...listAnnualGoal];
                    
        priceAll += FormatPrice('en-US', newData[index].january);
        priceAll += FormatPrice('en-US', newData[index].february);
        priceAll += FormatPrice('en-US', newData[index].march);
        priceAll += FormatPrice('en-US', newData[index].april);
        priceAll += FormatPrice('en-US', newData[index].may);
        priceAll += FormatPrice('en-US', newData[index].june);
        priceAll += FormatPrice('en-US', newData[index].july);
        priceAll += FormatPrice('en-US', newData[index].august);
        priceAll += FormatPrice('en-US', newData[index].september);
        priceAll += FormatPrice('en-US', newData[index].october);
        priceAll += FormatPrice('en-US', newData[index].november);
        priceAll += FormatPrice('en-US', newData[index].december);
        
        switch (type) {
            case 'annual_goal': 
                    newData[index][type] = ShowPriceBr(value);              
                break;

            case 'january': case 'february': case 'march': case 'april': case 'may': case 'june': 
            case 'july': case 'august': case 'september': case 'october': case 'november': case 'december':

                    priceAll = priceAll - FormatPrice('en-US', newData[index][type]);
                    priceAll = priceAll + FormatPrice('en-US', value);
                    let priceAnnualGoad = newData[index]['annual_goal'].replaceAll('.', '').replace(',', '.');

                    if(priceAll > priceAnnualGoad){                        
                        props.CallbackError('O valor preenchido excede o valor total da meta definida, para preencher o valor "' + ShowPriceBr(value) + '" é necessário atualizar o valor da meta!')
                    }else {
                        newData[index][type] = ShowPriceBr(value);
                    }                
                break;
        
            default:
                    newData[index][type] = value;
                break;
        }
        setListAnnualGoal(newData);
    }
    /* end */

    /* list email */
    function DeleteListEmail(id, index, value){
        if(id == 0){            
            const newData = [...listEmail];
            newData.splice(index, 1);
            setListEmail(newData);
        }else {
            props.AltAccess("settings", "delete_email", "Deletar e-mail", id, value, 0);
        }
    }
    function HandleDataListEmail(type, index, value){
        const newData = [...listEmail];
        newData[index][type] = value;
        setListEmail(newData);
    }
    /* end */
    
    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Settings(props.userId, listEmail, listAnnualGoal, props.CallbackSuccess, props.CallbackError);
    }
    
    useEffect(()=>{
        RegisterDataPage('settings', setDataPage);
        return ()=>{
            UnRegisterDataPage('settings', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setListEmail(InitialData('list_email'));
        setListAnnualGoal(InitialData('annual_goal'));
    }, [dataPage]);

    return(
        <form className="page_content page_settings" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="no_return" search="" title="Configurações" OpenPage={ props.OpenPage } page="settings" idPage={ 0 } qtdPage={ 0 } historic={ [] } />

            <div className="show_page_data">
                <div className="type_title">                    
                    <div className="">Lista de e-mails</div>
                    <div className="" onClick={ ()=>{ setListEmail([...listEmail, { "id": 0, "type": { "value": "", "label": "#", }, "name": "", "email": "" }]); } }>
                        Adicionar novo e-mail
                    </div>
                </div>
                <div className="list_input_data">
                    {
                        listEmail.length > 0 ?
                        listEmail.map((elem, index)=>{
                            return(
                                <div className="div_show_btn" key={ index }>
                                    <Comp_Select index={ index } name="Tipo de e-mail" className="type_email" opt={ selectTypeEmail } setValue={ HandleDataListEmail } value={ elem.type } input="type" />

                                    <Comp_Input className="" type="text" index={ index } setValue={ HandleDataListEmail } value={ elem.name } input="name" maxLength={ 140 } placeholder="" required={ true } name="Nome" />

                                    <Comp_Input className="email" type="email" index={ index } setValue={ HandleDataListEmail } value={ elem.email } input="email" maxLength={ 60 } placeholder="" required={ true } name="E-mail*" />

                                    <div className="" onClick={ ()=>{ DeleteListEmail(elem.id, index, elem.email) } }>
                                        <Svg_Delete className="icons" color={ colorIcon } />
                                    </div>
                                </div>
                            )
                        })
                        : <div className="no_data">Nenhum e-mail encontrado...</div>
                    }
                </div>
            </div>

            <div className="show_page_data">
                <div className="type_title">
                    <div className="">Metas</div>
                    <div className="" onClick={ ()=>{ setListAnnualGoal([...listAnnualGoal, { "id": 0, "year": 0, "annual_goal": "0,00", "obs": "", "january": "0,00", "february": "0,00", "march": "0,00", "april": "0,00", "may": "0,00", "june": "0,00", "july": "0,00", "august": "0,00", "september": "0,00", "october": "0,00", "november": "0,00", "december": "0,00" }]); } }>
                        Adicionar nova meta
                    </div>
                </div>
                <div className="list_input_data">
                    {
                        listAnnualGoal.length > 0 ?
                        listAnnualGoal.map((elem, index)=>{
                            return(
                                <div className="div_show_btn list_annual_goal" key={ index }>
                                    <div className="show_price">
                                        <Comp_Input className="year" type="text" index={ index } setValue={ HandleDataAnnualGoal } value={ elem.year } input="year" maxLength={ 4 } placeholder="" required={ true } name="Ano*" />

                                        <Comp_Input className="price_all" type="text" index={ index } setValue={ HandleDataAnnualGoal } value={ elem.annual_goal } input="annual_goal" maxLength={ 20 } placeholder="" required={ true } name="Valor da meta pro ano R$*" />
                                        
                                        <div className="" onClick={ ()=>{ DeleteAnnualGoal(elem.id, index, elem.year) } }>
                                            <Svg_Delete className="icons" color={ colorIcon } />
                                        </div>
                                    </div>

                                    <div className="list_month">
                                        <Comp_Input className="price" type="text" index={ index } setValue={ HandleDataAnnualGoal } value={ elem.january } input="january" maxLength={ 20 } placeholder="" required={ true } name="Janeiro*" />
                                    
                                        <Comp_Input className="price" type="text" index={ index } setValue={ HandleDataAnnualGoal } value={ elem.february } input="february" maxLength={ 20 } placeholder="" required={ true } name="Fevereiro*" />
                                        
                                        <Comp_Input className="price" type="text" index={ index } setValue={ HandleDataAnnualGoal } value={ elem.march } input="march" maxLength={ 20 } placeholder="" required={ true } name="Março*" />
                                        
                                        <Comp_Input className="price" type="text" index={ index } setValue={ HandleDataAnnualGoal } value={ elem.april } input="april" maxLength={ 20 } placeholder="" required={ true } name="Abril*" />
                                        
                                        <Comp_Input className="price" type="text" index={ index } setValue={ HandleDataAnnualGoal } value={ elem.may } input="may" maxLength={ 20 } placeholder="" required={ true } name="Maio*" />
                                        
                                        <Comp_Input className="price" type="text" index={ index } setValue={ HandleDataAnnualGoal } value={ elem.june } input="june" maxLength={ 20 } placeholder="" required={ true } name="Junho*" />
                                    </div>
                                    
                                    <div className="list_month">
                                        <Comp_Input className="price" type="text" index={ index } setValue={ HandleDataAnnualGoal } value={ elem.july } input="july" maxLength={ 20 } placeholder="" required={ true } name="Julho*" />
                                        
                                        <Comp_Input className="price" type="text" index={ index } setValue={ HandleDataAnnualGoal } value={ elem.august } input="august" maxLength={ 20 } placeholder="" required={ true } name="Agosto*" />
                                        
                                        <Comp_Input className="price" type="text" index={ index } setValue={ HandleDataAnnualGoal } value={ elem.september } input="september" maxLength={ 20 } placeholder="" required={ true } name="Setembro*" />
                                        
                                        <Comp_Input className="price" type="text" index={ index } setValue={ HandleDataAnnualGoal } value={ elem.october } input="october" maxLength={ 20 } placeholder="" required={ true } name="Outubro*" />
                                        
                                        <Comp_Input className="price" type="text" index={ index } setValue={ HandleDataAnnualGoal } value={ elem.november } input="november" maxLength={ 20 } placeholder="" required={ true } name="Novembro*" />
                                        
                                        <Comp_Input className="price" type="text" index={ index } setValue={ HandleDataAnnualGoal } value={ elem.december } input="december" maxLength={ 20 } placeholder="" required={ true } name="Dezembro*" />
                                    </div>

                                    <Comp_Textarea index={ index } name="Observação" setValue={ HandleDataAnnualGoal } value={ elem.obs } />
                                </div>
                            )
                        })
                        : <div className="no_data">Nenhuma meta encontrada...</div>
                    }
                </div>
            </div>
        </form>
    );
}